<template>
  <b-card
    no-body
    class="border border-dark shadow-none"
  >
    <b-card-header
      role="tab"
    >
      <slot name="header">
        <QuoteProductCard
          :product="product"
          :is-visible="visible"
          :collapse-item-id="collapseItemId"
          :show-edit-button="showEditButton"
          :show-delete-button="showDeleteButton"
          :load-edit-product-modal="loadEditProductModal"
          :disabled-form-components="disabledFormComponents"
          :set-pieces-query="setPiecesQuery"
        />
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <b-card-body class="px-0 py-0">
        <slot v-if="visible">
          <QuoteProductPiecesList
            :product="product"
            :selectable="true"
            :pieces="product.pieces"
            :show-actions-button="showPiecesListActionsButtons"
          />
        </slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCollapse, BCardBody,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import QuoteProductCard from '@/modules/trade/quotes/components/cards/QuoteProductCard.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCollapse,
    BCardHeader,
    QuoteProductCard,
    QuoteProductPiecesList: () => import(
      '@/modules/trade/quotes/components/lists/QuoteProductPiecesList.vue'
    ),
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    loadEditProductModal: {
      type: Boolean,
      default: true,
    },

    // * 22/11/2022 - TAG: For quote details
    disabledFormComponents: {
      type: Boolean,
      default: false,
    },
    showPiecesListActionsButtons: {
      type: Boolean,
      default: true,
    },
    setPiecesQuery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '',
      openOnHover: this.$parent.hover,
    }
  },
  created() {
    this.collapseItemId = uuidv4()
    this.visible = this.isVisible
  },
}
</script>
