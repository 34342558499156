var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[(_vm.$ability.can('read', 'Product_images'))?_c('b-col',{attrs:{"lg":"1","md":"2"}},[_c('ProductCardSwiper',{attrs:{"title":_vm.product.Name,"images-small":_vm.product.images.small,"images-full":_vm.product.images.full,"available":_vm.available}})],1):_vm._e(),_c('b-col',{attrs:{"lg":"11","md":"10"}},[_c('b-row',[(_vm.$ability.can('read', 'Product_brand'))?_c('b-col',{attrs:{"md":_vm.colsNumber}},[_c('InformationBasicCard',{attrs:{"title":"Marca","tags":[{ value: _vm.product.name_brand, type: 'string' }]}})],1):_vm._e(),(_vm.$ability.can('read', 'Product_model'))?_c('b-col',{attrs:{"md":_vm.colsNumber}},[_c('InformationBasicCard',{attrs:{"title":"Modelo","tags":[{ value: _vm.product.Model, type: 'string' }]}})],1):_vm._e(),(_vm.$ability.can('read', 'Product_category'))?_c('b-col',{attrs:{"md":_vm.colsNumber}},[_c('InformationBasicCard',{attrs:{"title":"Categoría y subcategoría","tags":[{ value: ("Categoría: " + (_vm.product.TrueCategory ? _vm.product.TrueCategory.Name : 'Sin categoría')), type: 'string' },
                    { value: ("Subcategoría: " + (_vm.product.name_categorie)), type: 'string' }]}})],1):_vm._e(),(_vm.product.Type === 'Nuevo' && _vm.$ability.can('read', 'Product_code'))?_c('b-col',{attrs:{"md":_vm.colsNumber}},[_c('InformationBasicCard',{attrs:{"title":"Código interno","tags":[{ value: _vm.product.Code, type: 'string' }]}})],1):_vm._e(),(_vm.$ability.can('read', 'Product_color_paint') && _vm.$ability.can('read', 'Product_color_tapestry'))?_c('b-col',{attrs:{"md":_vm.colsNumber}},[_c('InformationBasicCard',{attrs:{"title":"Color pintura y tapiz","tags":[{ name: _vm.product.NamePaint, value: _vm.product.CodePaint, type: 'color' },
                    { name: _vm.product.NameTapestry, value: _vm.product.CodeTapestry, type: 'color' }]}})],1):_vm._e(),(_vm.product.Type === 'Nuevo' && _vm.$ability.can('read', 'Product_item'))?_c('b-col',{attrs:{"md":_vm.colsNumber}},[_c('InformationBasicCard',{attrs:{"title":"Item","tags":[{ value: _vm.product.Item, type: 'string' }]}})],1):_vm._e(),_c('b-col',{attrs:{"md":_vm.colsNumber}},[_c('InformationBasicCard',{attrs:{"title":"Cantidad","tags":[{ value: _vm.piecesCount, type: 'string' }]}})],1),(_vm.$ability.can('read', 'Product_prices_list'))?_c('b-col',{attrs:{"md":_vm.colsNumber}},[(_vm.product.Type === 'Usado')?[_c('div',{staticClass:"mt-1"},[_c('h5',{staticClass:"font-weight-bolder"},[_vm._v(" Precios ")]),_c('b-form-radio-group',{attrs:{"options":_vm.pricesListOptions,"name":_vm.idRadioButtons,"disabled":_vm.disabledFormComponents || _vm.circuitWithOfferPriceSelected !== null,"stacked":""},model:{value:(_vm.selectedPrice),callback:function ($$v) {_vm.selectedPrice=$$v},expression:"selectedPrice"}})],1)]:[(_vm.activatePricesList)?[(_vm.normalPricesDefined)?_c('InformationBasicCard',{attrs:{"title":"Precios","tags":[{ value: _vm.product.PriceMin, prepend: 'Mín:', type: 'currency' },
                        { value: _vm.product.PriceMax, prepend: 'Máx:', type: 'currency' } ].concat( _vm.product.OfferPrice ? [{ value: _vm.product.OfferPrice, prepend: 'Oferta:', type: 'currency', style: 'color: #FF7766;' }] : [])},scopedSlots:_vm._u([(!_vm.disabledFormComponents || _vm.isOfferPriceSelected)?{key:"selected-offer-price",fn:function(){return [_c('b-form-checkbox',{staticStyle:{"margin-left":"-25px !important"},attrs:{"disabled":_vm.disabledFormComponents || _vm.circuitWithOfferPriceSelected !== null},model:{value:(_vm.isOfferPriceSelected),callback:function ($$v) {_vm.isOfferPriceSelected=$$v},expression:"isOfferPriceSelected"}})]},proxy:true}:null],null,true)}):_c('InformationBasicCard',{attrs:{"title":"Precios","tags":[{ value: 'Precios por definir', type: 'string' }]}})]:_vm._e(),(_vm.activateWholesalerPricesList)?[(_vm.wholesalerPricesDefined)?_c('InformationBasicCard',{attrs:{"title":"Mayorista","tags":[{ value: _vm.product.PriceMinWholesaler, prepend: 'Mín:', type: 'currency' },
                        { value: _vm.product.PriceMaxWholesaler, prepend: 'Máx:', type: 'currency' }]}}):(_vm.normalPricesDefined)?_c('InformationBasicCard',{attrs:{"title":"Precios","tags":[{ value: _vm.product.PriceMin, prepend: 'Mín:', type: 'currency' },
                        { value: _vm.product.PriceMax, prepend: 'Máx:', type: 'currency' }]}}):_c('InformationBasicCard',{attrs:{"title":"Precios","tags":[{ value: 'Precios por definir', type: 'string' }]}})]:_vm._e()]],2):_vm._e(),_c('b-col',{attrs:{"md":_vm.colsNumber}},[_c('div',{staticClass:"mt-1"},[_c('h5',{staticClass:"font-weight-bolder"},[_vm._v(" Precio unitario ")]),_c('TextInputWithValidation',{attrs:{"vid":_vm.unitPriceUUID,"rules":{
                required: _vm.circuitWithOfferPriceSelected === null,
                negative: _vm.negative,
                notalpha: _vm.notalpha,
                decimal: _vm.decimal,
                'min_value':_vm.valuePrice,
              },"type":"text","name":"precio unitario","maxlength":"12","placeholder":"100.00","prepend":"$","styled":false,"disabled":_vm.disabledFormComponents || _vm.circuitWithOfferPriceSelected !== null},on:{"input":_vm.onUpdateUnitPrice},model:{value:(_vm.unitPrice),callback:function ($$v) {_vm.unitPrice=$$v},expression:"unitPrice"}})],1)]),_c('b-col',{attrs:{"md":_vm.colsNumber}},[_c('InformationBasicCard',{attrs:{"title":"Subtotal","tags":[{ value: _vm.subtotal, type: 'currency' }]}})],1)],1)],1),_c('b-button',{staticClass:"btn-icon rounded-circle ml-1 toggle-button",attrs:{"variant":_vm.visible ? 'flat-primary' : 'flat-secondary'},on:{"click":_vm.collapse}},[_c('feather-icon',{attrs:{"icon":_vm.iconToggle}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }