<template>
  <b-container>
    <b-row>

      <!-- #region::Images carousel -->
      <b-col
        v-if="$ability.can('read', 'Product_images')"
        lg="1"
        md="2"
      >
        <ProductCardSwiper
          :title="product.Name"
          :images-small="product.images.small"
          :images-full="product.images.full"
          :available="available"
        />
      </b-col>
      <!-- #endregion::Images carousel -->

      <!-- #region::Main content -->
      <b-col
        lg="11"
        md="10"
      >
        <b-row>

          <!-- #region::Product brand -->
          <b-col
            v-if="$ability.can('read', 'Product_brand')"
            :md="colsNumber"
          >
            <InformationBasicCard
              title="Marca"
              :tags="[{ value: product.name_brand, type: 'string' }]"
            />
          </b-col>
          <!-- #endregion::Product brand -->

          <!-- #region::Product model -->
          <b-col
            v-if="$ability.can('read', 'Product_model')"
            :md="colsNumber"
          >
            <InformationBasicCard
              title="Modelo"
              :tags="[{ value: product.Model, type: 'string' }]"
            />
          </b-col>
          <!-- #endregion::Product model -->

          <!-- #region::Product category -->
          <b-col
            v-if="$ability.can('read', 'Product_category')"
            :md="colsNumber"
          >
            <InformationBasicCard
              title="Categoría y subcategoría"
              :tags="[{ value: `Categoría: ${product.TrueCategory ? product.TrueCategory.Name : 'Sin categoría'}`, type: 'string' },
                      { value: `Subcategoría: ${product.name_categorie}`, type: 'string' }]"
            />
          </b-col>
          <!-- #endregion::Product category -->

          <!-- #region::Product internal code -->
          <b-col
            v-if="product.Type === 'Nuevo' && $ability.can('read', 'Product_code')"
            :md="colsNumber"
          >
            <InformationBasicCard
              title="Código interno"
              :tags="[{ value: product.Code, type: 'string' }]"
            />
          </b-col>
          <!-- #endregion::Product internal code -->

          <!-- #region::Tapestry and paing color product -->
          <b-col
            v-if="$ability.can('read', 'Product_color_paint') && $ability.can('read', 'Product_color_tapestry')"
            :md="colsNumber"
          >
            <InformationBasicCard
              title="Color pintura y tapiz"
              :tags="[{ name: product.NamePaint, value: product.CodePaint, type: 'color' },
                      { name: product.NameTapestry, value: product.CodeTapestry, type: 'color' }]"
            />
          </b-col>
          <!-- #endregion::Tapestry and paing color product -->

          <!-- #region::Product item -->
          <b-col
            v-if="product.Type === 'Nuevo' && $ability.can('read', 'Product_item')"
            :md="colsNumber"
          >
            <InformationBasicCard
              title="Item"
              :tags="[{ value: product.Item, type: 'string' }]"
            />
          </b-col>
          <!-- #endregion::Product item -->

          <!-- #region::Quantity pieces producto for quote -->
          <b-col
            :md="colsNumber"
          >
            <InformationBasicCard
              title="Cantidad"
              :tags="[{ value: piecesCount, type: 'string' }]"
            />
          </b-col>
          <!-- #endregion::Quantity pieces producto for quote -->

          <!-- #region::Product prices list -->
          <b-col
            v-if="$ability.can('read', 'Product_prices_list')"
            :md="colsNumber"
          >

            <!-- #region::Used and remanofactured prices -->
            <template v-if="product.Type === 'Usado'">
              <div
                class="mt-1"
              >
                <h5 class="font-weight-bolder">
                  Precios
                </h5>
                <b-form-radio-group
                  v-model="selectedPrice"
                  :options="pricesListOptions"
                  :name="idRadioButtons"
                  :disabled="disabledFormComponents || circuitWithOfferPriceSelected !== null"
                  stacked
                />
              </div>
            </template>
            <!-- #endregion::Used and remanofactured prices -->

            <!-- #region::New product prices -->
            <template v-else>
              <template v-if="activatePricesList">
                <InformationBasicCard
                  v-if="normalPricesDefined"
                  title="Precios"
                  :tags="[{ value: product.PriceMin, prepend: 'Mín:', type: 'currency' },
                          { value: product.PriceMax, prepend: 'Máx:', type: 'currency' },
                          ... product.OfferPrice ? [{ value: product.OfferPrice, prepend: 'Oferta:', type: 'currency', style: 'color: #FF7766;' }] : []]"
                >
                  <template
                    v-if="!disabledFormComponents || isOfferPriceSelected"
                    v-slot:selected-offer-price
                  >
                    <b-form-checkbox
                      v-model="isOfferPriceSelected"
                      style="margin-left: -25px !important;"
                      :disabled="disabledFormComponents || circuitWithOfferPriceSelected !== null"
                    />
                  </template>
                </InformationBasicCard>
                <InformationBasicCard
                  v-else
                  title="Precios"
                  :tags="[{ value: 'Precios por definir', type: 'string' }]"
                />
              </template>
              <template v-if="activateWholesalerPricesList">
                <InformationBasicCard
                  v-if="wholesalerPricesDefined"
                  title="Mayorista"
                  :tags="[{ value: product.PriceMinWholesaler, prepend: 'Mín:', type: 'currency' },
                          { value: product.PriceMaxWholesaler, prepend: 'Máx:', type: 'currency' }]"
                />
                <InformationBasicCard
                  v-else-if="normalPricesDefined"
                  title="Precios"
                  :tags="[{ value: product.PriceMin, prepend: 'Mín:', type: 'currency' },
                          { value: product.PriceMax, prepend: 'Máx:', type: 'currency' }]"
                />
                <InformationBasicCard
                  v-else
                  title="Precios"
                  :tags="[{ value: 'Precios por definir', type: 'string' }]"
                />
              </template>

            </template>
            <!-- #endregion::New product prices -->

          </b-col>
          <!-- #endregion::Product prices list -->

          <!-- #region::Product unit price input -->
          <b-col :md="colsNumber">
            <div
              class="mt-1"
            >
              <h5 class="font-weight-bolder">
                Precio unitario
              </h5>
              <TextInputWithValidation
                v-model="unitPrice"
                :vid="unitPriceUUID"
                :rules="{
                  required: circuitWithOfferPriceSelected === null,
                  negative,
                  notalpha,
                  decimal,
                  'min_value':valuePrice,
                }"
                type="text"
                name="precio unitario"
                maxlength="12"
                placeholder="100.00"
                prepend="$"
                :styled="false"
                :disabled="disabledFormComponents || circuitWithOfferPriceSelected !== null"
                @input="onUpdateUnitPrice"
              />
            </div>
          </b-col>
          <!-- #endregion::Product unit price input -->

          <!-- #region::Product subtotal -->
          <b-col
            :md="colsNumber"
          >
            <InformationBasicCard
              title="Subtotal"
              :tags="[{ value: subtotal, type: 'currency' }]"
            />
          </b-col>
          <!-- #endregion::Product subtotal -->

        </b-row>
      </b-col>
      <!-- #endregion::Main content -->

      <!-- #region::Toggle Button -->
      <b-button
        :variant="visible ? 'flat-primary' : 'flat-secondary'"
        class="btn-icon rounded-circle ml-1 toggle-button"
        @click="collapse"
      >
        <feather-icon :icon="iconToggle" />
      </b-button>
      <!-- #endregion::Toggle Button -->

    </b-row>
  </b-container>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import { extend } from 'vee-validate'
import {
  required, decimal, negative, notalpha,
} from '@validations'
import {
  BRow, BCol, BContainer, BFormRadioGroup, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import validateCurrency from '@/helpers/CurrencyFormat'
import ProductCardSwiper from '@/components/swipers/ProductCardSwiper.vue'
import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BContainer,
    BFormCheckbox,
    BFormRadioGroup,
    ProductCardSwiper,
    InformationBasicCard,
    TextInputWithValidation,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    available: {
      type: Boolean,
      default: true,
    },

    // * 22/11/2022 - TAG: For quote details
    disabledFormComponents: {
      type: Boolean,
      default: false,
    },

    iconToggle: {
      type: String,
      default: 'ChevronDownIcon',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      unitPrice: '',

      pricesListOptions: [],
      selectedPrice: '',
      valuePrice: '',
      isOfferPriceSelected: false,

      // * 05/10/2022 - TAG: Validations
      decimal,
      required,
      negative,
      notalpha,

      idRadioButtons: '',
      ownerOrderRole: '',
    }
  },
  computed: {
    ...mapGetters({
      getQuote: 'quotes/getQuote',
      getQuoteCircuits: 'quotes/getQuoteCircuits',
      getCircuitsWithOfferPriceSelected: 'quotes/getCircuitsWithOfferPriceSelected',
    }),
    quote: {
      get() { return this.getQuote },
    },
    quoteCircuits: {
      get() { return this.getQuoteCircuits },
    },
    circuitsWithOfferPriceSelected: {
      get() { return this.getCircuitsWithOfferPriceSelected },
    },
    piecesCount: {
      get() {
        if (this.$route.name === 'new-quote') {
          return this.product.pieces.length
        }

        return this.product.pieces.filter(
          piece => piece.Status === 1,
        ).length
      },
    },
    colsNumber() {
      return this.product.Type === 'Nuevo' ? 2 : 3
    },
    subtotal() {
      if (this.$route.name === 'new-quote') {
        return this.unitPrice * this.piecesCount || '0'
      }

      return this.unitPrice * this.piecesCount || '0'
    },
    unitPriceUUID() {
      return uuidv4()
    },
    circuit() {
      const circuit = this.quoteCircuits.find(c => c.uuid === this.product.uuidCircuit)

      return circuit ?? null
    },
    circuitWithOfferPriceSelected() {
      const circuit = this.circuitsWithOfferPriceSelected.find(c => c.uuid === this.product.uuidCircuit)

      return circuit ?? null
    },
    maxPrice() {
      if (this.product.MaxSuggestedPrice) {
        return `Precio máximo: ${this.isValidCurrency(this.product.MaxSuggestedPrice)}`
      }

      return 'Precio máximo: sin especificar'
    },
    activatePricesList() {
      if (this.canShowPricesList && this.canShowWholesalerPrices && this.ownerOrderRole === 'Mayorista') {
        return false
      }

      if (this.canShowPricesList && this.canShowWholesalerPrices && this.ownerOrderRole === 'Vendedor') {
        return true
      }
      return this.canShowPricesList
    },
    activateWholesalerPricesList() {
      if (this.canShowPricesList && this.canShowWholesalerPrices && this.ownerOrderRole === 'Mayorista') {
        return true
      }

      if (this.canShowPricesList && this.canShowWholesalerPrices && this.ownerOrderRole === 'Vendedor') {
        return false
      }

      return this.canShowWholesalerPrices
    },
    canShowPricesList() {
      const canShowPricesList = this.$ability.can('read', 'Product_prices_list') && this.baseListPrice
      return canShowPricesList
    },
    canShowWholesalerPrices() {
      const canShowWholesalerPrices = this.$ability.can('read', 'Product_prices_wholesaler') && this.baseWholesalerPrice
      return canShowWholesalerPrices
    },
    normalPricesDefined() {
      return this.product.PriceMin && this.product.PriceMax
    },
    wholesalerPricesDefined() {
      return this.product.PriceMinWholesaler && this.product.PriceMaxWholesaler
    },
    baseListPrice() {
      if (this.userData.Role !== 'Mayorista') {
        return true
      }

      return this.checkPrices
    },
    baseWholesalerPrice() {
      if (this.userData.Role !== 'Mayorista') {
        return true
      }

      return !this.checkPrices
    },
    checkPrices() {
      if (!this.normalPricesDefined && this.wholesalerPricesDefined) {
        return false
      }

      if (this.normalPricesDefined && this.wholesalerPricesDefined) {
        return false
      }

      if (!this.normalPricesDefined && !this.wholesalerPricesDefined) {
        return true
      }

      if (this.normalPricesDefined && !this.wholesalerPricesDefined) {
        return true
      }

      return true
    },
    availableProduct() {
      if (this.$route.name !== 'quote-details') {
        return true
      }

      return this.product.Status === 1
    },
  },
  watch: {
    selectedPrice() {
      if (this.product.Type === 'Usado') {
        switch (this.selectedPrice) {
          case 'PriceLf':
            this.valuePrice = this.product.PriceLf
            break

          case 'PriceDr':
            this.valuePrice = this.product.PriceDr
            break

          case 'OfferPrice':
            this.valuePrice = this.product.OfferPrice
            break

          default:
            this.valuePrice = this.product.PriceDr + 1
            break
        }

        this.product.unitPriceOrigin = this.valuePrice
        this.product.unitPriceType = this.selectedPrice
      }
    },
    isOfferPriceSelected(isOfferPriceSelected) {
      if (isOfferPriceSelected) {
        this.valuePrice = this.product.OfferPrice
        this.selectedPrice = 'OfferPrice'
      } else {
        this.checkNewAndRemanufacturedPrices()
      }
      this.product.unitPriceOrigin = this.valuePrice
      this.product.unitPriceType = this.selectedPrice
    },
    circuitsWithOfferPriceSelected() {
      if (this.circuitWithOfferPriceSelected) {
        this.unitPrice = ''

        if (this.product.Type === 'Usado') {
          this.selectedPrice = 'PriceLf'
          this.valuePrice = this.product.PriceLf
        } else {
          this.isOfferPriceSelected = false
        }
      }
    },
  },

  /**
  * Hook que se ejecuta cuando se crea el componente
  *
  * @summary Si el producto es usado, se establecen los precios por defecto. Si el producto
  * es nuevo o remanufacturado, y cuenta con precio de oferta, se establece como precio seleccionado.
  * Si el producto es nuevo o remanufacturado, y no cuenta con precio de oferta, se establece el precio
  * mínimo como precio seleccionado
  */
  created() {
    if (this.product.Type === 'Usado') {
      // Used products have different price options
      this.pricesListOptions = [
        { text: `L/F: ${this.isValidCurrency(this.product.PriceLf)}`, value: 'PriceLf' },
        { html: `D/R: ${this.isValidCurrency(this.product.PriceDr)} <br/><small>${this.maxPrice}</small>`, value: 'PriceDr' },
        ...this.product.OfferPrice ? [{ html: `<span style="color: #FF7766;">Oferta: ${this.isValidCurrency(this.product.OfferPrice)}</span>`, value: 'OfferPrice' }] : [],
        { text: 'R:', value: 'R' },
      ]

      // Set default price as L/F
      this.selectedPrice = 'PriceLf'
      this.valuePrice = this.product.PriceLf
    } else if (this.product.unitPriceType === 'OfferPrice') {
      // Offer price is only available for new and remanufactured products
      this.isOfferPriceSelected = true
    } else {
      // New and remanufactured products have different price options
      this.checkNewAndRemanufacturedPrices()
      this.product.unitPriceOrigin = this.valuePrice
      this.product.unitPriceType = this.selectedPrice
    }

    extend('min_value', {
      params: ['target'],
      validate(value, { target }) {
        return Number(value) >= Number(target)
      },
      message(field, { target }) {
        return `El campo ${field} debe ser mayor o igual a ${validateCurrency(target)}`
      },
    })

    this.idRadioButtons = uuidv4()

    if (this.$route.name === 'quote-details') {
      this.unitPrice = this.product.UnitPrice
      this.valuePrice = this.product.UnitPriceOrigin
      this.selectedPrice = this.product.UnitPriceType

      if (this.product.UnitPriceType === 'OfferPrice') {
        this.isOfferPriceSelected = true
      }

      this.ownerOrderRole = this.quote.user[0].Role
    }

    if (this.$route.name === 'update-quote' && this.product.unitPrice > 0) {
      this.unitPrice = this.product.unitPrice
      this.valuePrice = this.product.unitPriceOrigin
      this.selectedPrice = this.product.unitPriceType
    }
  },
  methods: {
    ...mapActions({
      updateQuoteProduct: 'quotes/updateQuoteProduct',
    }),
    onUpdateUnitPrice() {
      this.product.unitPrice = Number(this.unitPrice)
    },
    collapse() {
      this.$emit('toggle-collapse')
    },
    checkNewAndRemanufacturedPrices() {
      if (this.product.Type === 'Nuevo') {
        if (this.userData.Role === 'Mayorista') {
          this.selectedPrice = this.product.PriceMinWholesaler ? 'PriceMinWholesaler' : 'PriceMin'
          this.valuePrice = this.product.PriceMinWholesaler ? this.product.PriceMinWholesaler : this.product.PriceMin
        } else {
          this.selectedPrice = this.product.PriceMin ? 'PriceMin' : 'PriceMinWholesaler'
          this.valuePrice = this.product.PriceMin ? this.product.PriceMin : this.product.PriceMinWholesaler
        }
      } else {
        this.selectedPrice = 'PriceMin'
        this.valuePrice = this.product.PriceMin
      }
    },

    /**
    * Validacion de datos numéricos
    *
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },
  },
}
</script>
